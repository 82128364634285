
import Vue from 'vue';
import { Component, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import StatusMixin from '@/shared/mixins/status.mixin';
import client from '@/shared/plugins/content';

@Component({
  layout: 'AppMain',
  name: 'help-center',
})
export default class HelpCenterIndex extends mixins(StatusMixin) {
  // selectedPost!: any;

  posts: any[] = [];

  mounted() {
    client
      .getEntries({
        content_type: 'helpPosts',
      })
      .then((response: { items: any[] }) => {
        this.posts = response.items;
      });
  }

  OpenPost(post: any): any {
    const selectedPost = {
      thumbnail: post.fields.thumbnail.fields.file.url,
      title: post.fields.title,
      subtitle: post.fields.subtitle,
      media: post.fields.media[0].fields.file.url,
      text: post.fields.textContent,
    };
    this.$router.push({ name: 'organisation-help-center-post-id', params: { id: post.fields.postId, ...selectedPost } });
  }
}
